import React from "react";
import dynamic from "next/dynamic";

import styles from "./styles.module.scss";
import useIsMobile from "../../hooks/useIsMobile";
import HeaderBar from "components/HeaderBar";

const Space = dynamic(() => import("components/common/Space"), { ssr: false });
const FooterBar = dynamic(() => import("components/FooterBar"), { ssr: false });
const PushNotification = dynamic(() => import("components/PushNotification"), {
  ssr: false,
});

export default function Base({ children, browserName, ...props }) {
  const isMobile = useIsMobile();
  return (
    <div className={isMobile ? "" : styles.root}>
      <HeaderBar {...props} isMobile={isMobile} />
      <div id="page-base" className={isMobile ? "" : styles.main}>
        {isMobile && <Space minHeight="50px" />}
        {children}
        <Space minHeight="50px" />
        <FooterBar {...props} />
        <PushNotification />
      </div>
    </div>
  );
}
