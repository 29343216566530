import React, { forwardRef } from "react";
import styles from "./style.module.scss";
import cn from "classnames";
import Link from "next/link";

const MenuButton = forwardRef(
  ({ children, href, className, ...props }, ref) => {
    return (
      <Link href={href}>
        <a
          ref={ref}
          href={href}
          className={cn(styles.root, className)}
          {...props}
        >
          {children}
        </a>
      </Link>
    );
  }
);

export default MenuButton;
