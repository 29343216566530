export function stringToHslColor(str, s = 70, l = 80) {
  if (!str?.length) return `hsl(90, 70%, 80%)`;
  try {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  } catch (e) {
    return `hsl(90, 70%, 80%)`;
  }
}
