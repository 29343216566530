import { useEffect } from 'react';
import { AUTH_API_URL, SAFARI_PUSH_ID } from '../../../config/constants';
import { browserName as browser } from '../../../helpers/unsuportedBrowsersHelper';
import { GRANTED } from '../../../reducers/notification';
import useMemory from '../../../hooks/useMemory';

export function useSendNotification({ user, authUrl }) {
  const memory = useMemory();
  useEffect(() => {
    if (!user && !authUrl) return;
    if (!user) return;
    const isNotificationEnable = memory.get('isNotificationEnable');
    const isNotificationSend = memory.get('isNotificationSend');

    if (isNotificationEnable && !isNotificationSend) {
      getToken().then((token) => {
        if (token) {
          fetch(`${AUTH_API_URL}/user/subscribe/push`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, browser }),
            credentials: 'include',
          })
            .then(() => memory.set('isNotificationSend', 'true'))
            .catch((e) => console.log(e));
        }
      });
    }
  }, [user, authUrl]);

  async function getToken() {
    if ('safari' in window && 'pushNotification' in window.safari) {
      const permissionData = window.safari.pushNotification.permission(SAFARI_PUSH_ID);
      return await checkRemotePermission(permissionData);

      async function checkRemotePermission(permissionData) {
        if (permissionData.permission === GRANTED) {
          return permissionData.deviceToken;
        }
        return null;
      }
    } else {
      return await messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((token) => token)
        .catch((e) => console.log(e));
    }
  }
}
