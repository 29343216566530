import React from "react";
import cn from "classnames";
import Link from "next/link";

import rememberPage from "helpers/rememberPage";
import ProfileAvatar from "components/Profile/ProfileCard/ProfileAvatar";
import UserCircleIcon from "../../common/icons/UserCircleIcon";
import styles from "./styles.module.scss";

const ProfileMenu = React.forwardRef(
  ({ auth, show, position, style, ...props }, ref) => {
    return (
      <div
        className={cn(
          styles.root,
          show ? styles.show : styles.hide,
          position === "bottom" && styles.bottom,
          position === "right" && styles.right
        )}
        ref={ref}
        style={style}
        {...props}
      >
        <div className="text-center p-4">
          {auth.user ? (
            <>
              <ProfileAvatar
                picture={auth.user.picture || ""}
                firstName={auth.user.given_name || ""}
                latsName={auth.user.family_name || ""}
                nickname={auth.user.nickname || ""}
                placeholderFontSize="50px"
                size="106px"
              />
              <p className={cn(styles.mainText, styles.links, "mt-3")}>
                {`Hello, `}
                <Link href="/profile">
                  <a
                    href="/profile"
                    dangerouslySetInnerHTML={{ __html: auth.user.nickname }}
                  />
                </Link>
              </p>
              <p className={styles.links}>
                <Link href="/profile">
                  <a href="/profile" onClick={rememberPage}>
                    Profile
                  </a>
                </Link>
                {" | "}
                <a href="/auth/logout" onClick={rememberPage}>
                  Logout
                </a>
              </p>
            </>
          ) : (
            <>
              <UserCircleIcon
                width={56}
                className={styles.proMovieIcon}
                color="#212529"
              />
              <p className={styles.mainText}>You Are Not Logged In!</p>
              <p className={styles.links}>
                <a href={auth.authUrl} onClick={rememberPage}>
                  Login
                </a>
                {" | "}
                <a
                  href={auth.authUrl?.replace("login", "signup")}
                  onClick={rememberPage}
                >
                  Create New Account
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default ProfileMenu;
