import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import Link from "next/link";
import { Nav } from "react-bootstrap";

import NavLogo from "../NavLogo";
import useAuth from "hooks/useAuth";
import ProfileMenu from "../ProfileMenu";
import ProfileAvatar from "components/Profile/ProfileCard/ProfileAvatar";
import MenuItem from "./MenuItem";
import IconLinkItem from "./SocialLinkItem";
import { useSendNotification } from "../DefaultHeader/useSendNotification";
import socialNetworks from "./SocialLinkItem/socialNetworks";
import RSSIcon from "../../common/icons/RSSIcon";
import UserIcon from "../../common/icons/UserIcon";
import {
  onIconEnter,
  onIconLeave,
  onProfileEnter,
  onProfileLeave,
} from "../../../helpers/profileHelpers";

export default function HomeHeader({ headerMenu, isRss }) {
  const [profileOpen, setProfileOpen] = useState([false, false]);
  const auth = useAuth();
  useSendNotification(auth);

  return (
    <>
      <Nav className="ms-auto d-flex d-lg-none">
        <NavLogo />
      </Nav>
      {headerMenu && (
        <Nav className={["me-auto", "d-none", "d-lg-flex", styles.navLinks]}>
          {Array.isArray(headerMenu) &&
            headerMenu.map((menu, index) => <MenuItem key={index} {...menu} />)}
        </Nav>
      )}

      <Nav className="d-none position-relative d-xl-flex ms-auto">
        {Array.isArray(socialNetworks) &&
          socialNetworks.map(({ href, title, icon }, index) => (
            <IconLinkItem href={href} title={title} Icon={icon} key={index} />
          ))}

        {isRss && (
          <IconLinkItem
            href={isRss.href || ""}
            title={isRss.title || "Rss feed"}
            Icon={RSSIcon}
          />
        )}

        {auth.user ? (
          <Link href="/profile">
            <Nav.Link
              href="/profile"
              title="Profile"
              onMouseEnter={() => onIconEnter(setProfileOpen)}
              onMouseLeave={() => onIconLeave(setProfileOpen)}
              data-attr="profile"
              className={cn(styles.link, styles.iconLink)}
            >
              <ProfileAvatar
                picture={auth?.user?.picture || ""}
                firstName={auth?.user?.given_name || ""}
                latsName={auth?.user?.family_name || ""}
                nickname={auth?.user?.nickname || ""}
              />
            </Nav.Link>
          </Link>
        ) : (
          <Nav.Link
            href={auth?.authUrl}
            title="Login"
            onMouseEnter={() => onIconEnter(setProfileOpen)}
            onMouseLeave={() => onIconLeave(setProfileOpen)}
            className={cn(styles.link, styles.iconLink)}
          >
            <UserIcon />
          </Nav.Link>
        )}
        {/*  show={profileOpen} */}
        <ProfileMenu
          show={profileOpen.some((i) => i === true)}
          auth={auth}
          position="bottom"
          onMouseEnter={() => onProfileEnter(setProfileOpen)}
          onMouseLeave={() => onProfileLeave(setProfileOpen)}
        />
      </Nav>

      <Nav className="ms-auto d-flex d-xl-none">
        <Nav.Item
          className={cn(styles.link, styles.iconLink, "border-0")}
          onMouseEnter={() => onIconEnter(setProfileOpen)}
          onMouseLeave={() => onIconLeave(setProfileOpen)}
        >
          {auth.user ? (
            <Link href="/profile">
              <Nav.Link
                href="/profile"
                title="Profile"
                onMouseEnter={() => onIconEnter(setProfileOpen)}
                onMouseLeave={() => onIconLeave(setProfileOpen)}
              >
                <ProfileAvatar
                  picture={auth.user.picture || ""}
                  firstName={auth.user.given_name || ""}
                  latsName={auth.user.family_name || ""}
                  nickname={auth.user.nickname || ""}
                />
              </Nav.Link>
            </Link>
          ) : (
            <Nav.Link
              href={auth.authUrl}
              title="Login"
              onMouseEnter={() => onIconEnter(setProfileOpen)}
              onMouseLeave={() => onIconLeave(setProfileOpen)}
              className={cn(styles.link, styles.iconLink)}
            >
              <UserIcon />
            </Nav.Link>
          )}
          <ProfileMenu
            show={profileOpen.some((i) => i === true)}
            auth={auth}
            position="bottom"
            onMouseEnter={() => onProfileEnter(setProfileOpen)}
            onMouseLeave={() => onProfileLeave(setProfileOpen)}
          />
        </Nav.Item>
      </Nav>
    </>
  );
}
