import React from "react";
import NextHead from "next/head";

import { VERSION, FACEBOOK_APP_ID } from "../../config/constants";

export default function Head({ children }) {
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="preconnect" href="https://cdn.americasvoice.news" />
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap"
        as="style"
        onLoad="this.rel='stylesheet'"
      />

      <link
        rel="preload"
        href="https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Medium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="preload"
        href="https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="preload"
        href="https://cdn.americasvoice.news/static/fonts/avenir-next/AvenirNextCyr-Bold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin
      />
      <link
        rel="icon"
        href="https://cdn.americasvoice.news/static/favicons/cropped-favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://cdn.americasvoice.news/static/favicons/cropped-favicon-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="https://cdn.americasvoice.news/static/favicons/cropped-favicon-180x180.png"
      />
      <meta
        name="msapplication-TileImage"
        content="https://cdn.americasvoice.news/static/favicons/cropped-favicon-270x270.png"
      />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />
      <meta name="version" content={VERSION} />
      {children}
    </NextHead>
  );
}
