import React, { useState } from "react";
import cn from "classnames";
import Link from "next/link";
import { Nav } from "react-bootstrap";

import NavLogo from "../NavLogo";
import useAuth from "hooks/useAuth";
import ProfileMenu from "../ProfileMenu";
import ProfileAvatar from "components/Profile/ProfileCard/ProfileAvatar";
import { useSendNotification } from "./useSendNotification";
import UserIcon from "../../common/icons/UserIcon";
import styles from "./styles.module.scss";
import {
  onIconEnter,
  onIconLeave,
  onProfileEnter,
  onProfileLeave,
} from "../../../helpers/profileHelpers";

export default function DefaultHeader() {
  const [profileOpen, setProfileOpen] = useState([false, false]);
  const auth = useAuth();

  useSendNotification(auth);

  return (
    <>
      <Nav className="d-flex">
        <NavLogo />
      </Nav>
      <Nav className="ms-lg-auto d-flex">
        {auth.user ? (
          <Link href="/profile">
            <Nav.Link
              href="/profile"
              title="Profile"
              className={cn(styles.link, styles.iconLink, "me-0")}
              data-attr="profile"
              onMouseEnter={() => onIconEnter(setProfileOpen)}
              onMouseLeave={() => onIconLeave(setProfileOpen)}
            >
              <ProfileAvatar
                picture={auth.user.picture || ""}
                firstName={auth.user.given_name || ""}
                latsName={auth.user.family_name || ""}
                nickname={auth.user.nickname || ""}
              />
            </Nav.Link>
          </Link>
        ) : (
          <Nav.Link
            href={auth.authUrl}
            title="Login"
            onMouseEnter={() => onIconEnter(setProfileOpen)}
            onMouseLeave={() => onIconLeave(setProfileOpen)}
            className={cn(styles.link, styles.iconLink)}
          >
            <UserIcon width={18} color="currentColor" />
          </Nav.Link>
        )}
        <ProfileMenu
          show={profileOpen.some((i) => i === true)}
          auth={auth}
          position="bottom"
          onMouseEnter={() => onProfileEnter(setProfileOpen)}
          onMouseLeave={() => onProfileLeave(setProfileOpen)}
        />
      </Nav>
    </>
  );
}
