const prepareLink = (href, path) => {
  const removeSlashes = (link) => {
    let temp = link;
    if (!temp?.length) {
      return null;
    }
    if (temp?.[0] === "/") {
      temp = temp.slice(1);
    }
    if (temp?.[temp?.length - 1] === "/") {
      temp = temp.slice(0, -1);
    }
    return temp;
  };

  return {
    preparedPath: removeSlashes(path),
    preparedHref: removeSlashes(href),
  };
};

const isCurrentPage = (href, asPath) => {
  const { preparedPath, preparedHref } = prepareLink(href, asPath);
  if (href === "/" && preparedPath === "#copyright") {
    return true;
  }
  return preparedPath === preparedHref;
};

export default isCurrentPage;
