import React from "react";

function UserIcon({ width = 18, color = "#FFF", filled = false, ...props }) {
  return (
    <svg
      width={width}
      {...props}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={filled ? "0 0 448 512" : "0 0 512 512"}
    >
      {filled ? (
        <path
          fill={color}
          d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
        />
      ) : (
        <path
          fill={color}
          d="M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32C448 382.8 369.2 304 272 304zM48.99 464C56.89 400.9 110.8 352 176 352h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128c0-70.69-57.31-128-128-128S96 57.31 96 128C96 198.7 153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48z"
        />
      )}
    </svg>
  );
}

export default UserIcon;
