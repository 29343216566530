import React, { useState } from "react";
import dynamic from "next/dynamic";

import styles from "../styles.module.scss";

const ScrollableShowsList = dynamic(() => import("./ScrollableShowsList"), {
  ssr: false,
});
const ScrollableList = dynamic(() => import("./ScrollableList"), {
  ssr: false,
});

const LIST_TYPES = {
  categoriesAndShows: ScrollableShowsList,
  categoriesAndVideos: ScrollableShowsList,
  newsCategory: ScrollableList,
};

function ItemsList({ url, type, items, title = null }) {
  const [isOver, setIsOver] = useState(false);
  if (!items || !items?.length || !Object.keys(LIST_TYPES).includes(type)) {
    return null;
  }

  const Component = LIST_TYPES[type];

  return (
    <Component
      items={items}
      url={url}
      title={title}
      onMouseOver={() => setIsOver(true)}
      onMouseOut={() => setIsOver(false)}
      className={isOver ? styles.isOver : ""}
    />
  );
}

export default React.memo(ItemsList);
