import React from "react";

import styles from "./styles.module.scss";
import PlusIcon from "components/common/icons/PlusIcon";

export default function MobileNavButton({ onClick, menuOpen }) {
  const onCLick = (e) => {
    if (typeof onClick === "function") {
      return onClick(e);
    }
  };

  return (
    <div onClick={onCLick} className={styles.root}>
      {menuOpen ? (
        <div className={styles.rootClose}>
          <PlusIcon color="currentColor" width={16} />
        </div>
      ) : (
        <div className={styles.rootOpen}>
          <span />
          <span />
          <span />
          <span />
        </div>
      )}
    </div>
  );
}
