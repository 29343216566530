import React from "react";
import styles from "./styles.module.scss";
import Link from "next/link";

export default function NavLogo() {
  return (
    <Link href="/">
      <a href="/">
        <img
          width="60"
          height="31"
          className={styles.img}
          src={
            "https://cdn.americasvoice.news/static/images/nav-logo/logo-2x.png@60x31"
          }
          srcSet={
            "https://cdn.americasvoice.news/static/images/nav-logo/logo-2x.png@120x62 2x"
          }
          loading="lazy"
          alt="Logo"
        />
      </a>
    </Link>
  );
}
