import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import { Navbar } from "react-bootstrap";
import MobileNavButton from "./MobileNavButton";
import HomeHeader from "./HomeHeader";
import DefaultHeader from "./DefaultHeader";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const NavSidebar = dynamic(() => import("./NavSidebar"), { ssr: false });

export default function HeaderBar({
  latestVideos = [],
  headerMenu,
  sidebarMenu,
  headerType = "",
  isMobile,
}) {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isRss, setIsRss] = useState();

  useEffect(() => {
    setIsRss(document.querySelector('link[rel="alternate"]'));

    const menuCloseHandler = () => setMenuOpen(false);

    router.events.on("routeChangeComplete", menuCloseHandler);
    router.events.on("routeChangeError", menuCloseHandler);
    return () => {
      router.events.off("routeChangeComplete", menuCloseHandler);
      router.events.off("routeChangeError", menuCloseHandler);
    };
  }, []);

  const toggleMenu = useCallback(
    (e) => setMenuOpen((menuOpen) => !menuOpen),
    []
  );

  const header = useMemo(() => {
    return headerType === "default" ? (
      <DefaultHeader />
    ) : (
      <HomeHeader
        headerMenu={headerMenu}
        latestVideos={latestVideos}
        isRss={isRss}
      />
    );
  }, [headerType, isRss, headerMenu, latestVideos]);

  const headerStyle = useMemo(
    () => (isMobile ? { position: "fixed" } : null),
    [isMobile]
  );

  return (
    <header className={styles.root} style={headerStyle}>
      <Navbar
        style={headerStyle}
        className={cn(styles.navBar, "justify-content-between container-xl")}
        variant="dark"
      >
        <Navbar.Brand as="div">
          <MobileNavButton onClick={toggleMenu} menuOpen={menuOpen} />
        </Navbar.Brand>

        {header}

        <NavSidebar
          isMobile={isMobile}
          sidebarMenu={sidebarMenu}
          open={menuOpen}
          close={toggleMenu}
          isRss={isRss}
        />
      </Navbar>
    </header>
  );
}
