import React from "react";

function FacebookIcon({
  width = "11px",
  color = "#FFF",
  filled = false,
  ...props
}) {
  return (
    <svg
      width={filled ? "24" : width}
      {...props}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={filled ? "0 0 24 24" : "0 0 320 512"}
    >
      {filled ? (
        <path
          d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
          fill="#4064ac"
        />
      ) : (
        <path
          fill={color}
          d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
        />
      )}
    </svg>
  );
}

export default FacebookIcon;
