export function responsiveImage(src, width = 0, height = 0) {
  if (!src || typeof src !== "string") {
    return (
      "https://cdn.americasvoice.news/static/images/placeholders/thumbnail-16x9.jpg@" +
      width +
      "x" +
      height
    );
  }
  if (src?.indexOf("cdn.americasvoice.news") === -1) {
    return src;
  }
  return src + "@" + width + "x" + height;
}
