import React from "react";

function PlusIcon({
  width = 16,
  color = "#FFF",
  cross = false,
  centered = false,
  ...props
}) {
  const transformStyle = centered
    ? "rotate(45deg)"
    : "translateX(-50%) translateY(-50%) rotate(45deg) ";

  return (
    <svg
      width={width}
      height={width}
      style={
        cross
          ? {
              transform: transformStyle,
            }
          : undefined
      }
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
      />
    </svg>
  );
}

export default PlusIcon;
