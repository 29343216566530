import {
  FACEBOOK,
  INSTAGRAM,
  TWITTER,
  YOUTUBE,
} from "../../../../config/constants";
import FacebookIcon from "../../../common/icons/FacebookIcon";
import InstagramIcon from "../../../common/icons/InstagramIcon";
import TwitterIcon from "../../../common/icons/TwitterIcon";
import YoutubeIcon from "../../../common/icons/YoutubeIcon";

export default [
  {
    href: FACEBOOK,
    title: "Facebook",
    icon: FacebookIcon,
  },
  {
    href: TWITTER,
    title: "Twitter",
    icon: TwitterIcon,
  },
  {
    href: YOUTUBE,
    title: "YouTube",
    icon: YoutubeIcon,
  },
  {
    href: INSTAGRAM,
    title: "Instagram",
    icon: InstagramIcon,
  },
];
