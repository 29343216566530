import React from "react";
import { Nav } from "react-bootstrap";
import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import SubMenu from "../SubMenu";
import AngleRightIcon from "common/icons/AngleRightIcon";
import isCurrentPage from "helpers/isCurrentPage";

import styles from "./styles.module.scss";

function MenuItem({ title, url, submenu, styleType, viewType, newBadge }) {
  const { asPath } = useRouter();

  return (
    <Nav.Item className={cn(styles.navItem, styles[styleType])}>
      <Link href={url}>
        <Nav.Link
          href={url}
          className={cn(
            styles.link,
            "d-flex align-items-center justify-content-center px-3"
          )}
          active={isCurrentPage(url, asPath)}
          data-new={newBadge || undefined}
        >
          {title}
          {!!submenu && <AngleRightIcon down className={styles.icon} />}
        </Nav.Link>
      </Link>
      {submenu && (
        <SubMenu
          items={submenu}
          styleType={styleType}
          viewType={viewType}
          className={styles.subMenu}
        />
      )}
    </Nav.Item>
  );
}

export default MenuItem;
