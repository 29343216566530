import styles from "../styles.module.scss";
import cn from "classnames";
import { Nav } from "react-bootstrap";

export default function IconLinkItem({ href, title, Icon }) {
  return (
    <Nav.Link
      href={href}
      title={title}
      target="_blank"
      className={cn(styles.link, styles.iconLink)}
    >
      <Icon />
    </Nav.Link>
  );
}
