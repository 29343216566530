import React, { useState } from "react";
import cn from "classnames";

import MenuButton from "../../MenuButton";
import ItemsList from "./ItemsList";

import styles from "./styles.module.scss";

const CERTAIN_TYPES = [
  "categoriesAndShows",
  "categoriesAndVideos",
  "newsCategory",
];

function SubMenu({ items, styleType, viewType = "vertical", ...props }) {
  const firstValidItem = (items ?? []).find((item) => item.url);
  const [active, setActive] = useState(firstValidItem?.url ?? null);
  const [activeTitle, setActiveTitle] = useState(firstValidItem?.title ?? null);
  const [type, setType] = useState(firstValidItem?.type ?? null);
  const allShowsItems = firstValidItem?.items ?? [];
  const [sliderItems, setSliderItems] = useState(allShowsItems);

  const hoverHandler = ({ url, type, itemsArray, title, slug }) => {
    setActive(CERTAIN_TYPES.includes(type) ? url : null);
    setType(type);
    setActiveTitle(title);
    if (
      Array.isArray(items) &&
      items.some(({ type }) => type === "categoriesAndShows")
    ) {
      const filteredItems = allShowsItems.filter(({ category }) =>
        slug ? category.slug === slug : true
      );
      setSliderItems(filteredItems || []);
    } else {
      setSliderItems(itemsArray || []);
    }
  };

  const isActive = (slug, type) => {
    if (CERTAIN_TYPES.includes(type)) {
      return slug === active && styles.active;
    }
    return "";
  };

  return (
    <div
      className={cn(
        styles.root,
        styles[styleType],
        styles[viewType],
        props.className
      )}
    >
      <div className={cn(styles.linksWrapper, styles[styleType])}>
        {Array.isArray(items) &&
          items
            .filter((menuItem) => menuItem?.url)
            .map(
              (
                { title, url, slug, type, items: itemsArray, target },
                index
              ) => (
                <MenuButton
                  key={index}
                  className={cn(
                    styles.submenuLink,
                    isActive(url ?? slug, type)
                  )}
                  onMouseEnter={() =>
                    hoverHandler({ url, type, itemsArray, title, slug })
                  }
                  href={url}
                  target={target ? "_blank" : "_self"}
                >
                  {title}
                </MenuButton>
              )
            )}
      </div>
      {!!active && (
        <ItemsList
          url={active}
          type={type}
          items={sliderItems}
          title={activeTitle}
        />
      )}
    </div>
  );
}

export default SubMenu;
