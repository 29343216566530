import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SIZE_CHANGE } from '../reducers/devices';

export default function useIsMobile() {
  const innerWidth = useSelector((state) => state.devices.innerWidth);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(null);

  if (process.browser) {
    useEffect(() => {
      const resizeListener = () => dispatch({ type: SIZE_CHANGE, data: window.innerWidth });

      resizeListener();
      window.addEventListener('resize', resizeListener);

      return () => window.removeEventListener('resize', resizeListener);
    }, []);
  }

  useEffect(() => {
    if (!innerWidth) return;

    setIsMobile(innerWidth < 992);
  }, [innerWidth]);

  return isMobile;
}
