import React from "react";
import { Image } from "react-bootstrap";
import { stringToHslColor } from "helpers/stringToHslColor";
import { responsiveImage } from "helpers/cdn";

function ProfileAvatar({
  picture,
  nickname = "",
  placeholderFontSize = "14px",
  size = "30px",
}) {
  return !!picture ? (
    <Image
      src={responsiveImage(picture, 220)}
      roundedCircle
      style={{
        width: size,
        height: size,
        maxWidth: "150px",
        objectFit: "cover",
      }}
      className="shadow mx-auto"
      alt="User avatar image"
    />
  ) : (
    <div
      className="rounded-circle mx-auto text-black text-uppercase fw-bold p-2 d-flex align-items-center justify-content-center"
      style={{
        width: size,
        height: size,
        backgroundColor: stringToHslColor(nickname ? nickname[0] : ""),
      }}
    >
      <span style={{ fontSize: placeholderFontSize, color: "#000000" }}>
        {nickname ? nickname[0] : ""}{" "}
      </span>
    </div>
  );
}

export default ProfileAvatar;
